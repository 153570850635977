import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    // Only apply event listeners once to the toolbars
    const once = {
      once: true
    }
    
    // HTML for our buttons
    const underlineButtonHTML = `
      <button type="button" class="trix-button w-7" data-trix-attribute="underline"  title="underline">
        <span class="underline font-semibold">U</span>
      </button>`

    addEventListener("trix-initialize", function(event) {

      // Grab a reference to the toolbar
     const element = event.target
     const { toolbarElement, editor } = element

     // underline icons
     const strikeicon = toolbarElement.querySelector(".trix-button--icon-strike")
     strikeicon.insertAdjacentHTML("afterend", underlineButtonHTML)
   }, once)
  }
}