import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    // Only apply event listeners once to the toolbars
    const once = {
      once: true
    }

    addEventListener("trix-initialize", function(event) {

       // Grab a reference to the toolbar
      const element = event.target
      const { toolbarElement, editor } = element

      // inline code icons
      const blockCodeButton = toolbarElement.querySelector("[data-trix-attribute=code]")
      const inlineCodeButton = blockCodeButton.cloneNode(true)
    
      inlineCodeButton.hidden = true
      inlineCodeButton.dataset.trixAttribute = "inlineCode"
      blockCodeButton.insertAdjacentElement("afterend", inlineCodeButton)
      
      element.addEventListener("trix-selection-change", _ => {
        const type = getCodeFormattingType()
        blockCodeButton.hidden = type == "inline"
        inlineCodeButton.hidden = type == "block"
      })
    
      function getCodeFormattingType() {
        if (editor.attributeIsActive("code")) return "block"
        if (editor.attributeIsActive("inlineCode")) return "inline"
    
        const range = editor.getSelectedRange()
        if (range[0] == range[1]) return "block"
    
        const text = editor.getSelectedDocument().toString().trim()
        return /\n/.test(text) ? "block" : "inline"
      }
      
    }, once)
  }
}