import { Controller } from "@hotwired/stimulus"
import Trix from "trix"
import Rails from "@rails/ujs"

export default class extends Controller {

  connect() {

    // Only apply event listeners once to the toolbars
    const once = {
      once: true
    }

    // HTML for our buttons
    const tableButtonHTML        = `
      <button type="button" class="trix-button trix-button--icon trix-button--icon-table flex justify-center items-center" data-action="trix-exts-table#attachTable" title="Attach Table" tabindex="-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5 flex items-center">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
        </svg>
      </button>
    `

    addEventListener("trix-initialize", function(event) {

       // Grab a reference to the toolbar
      const element = event.target
      const { toolbarElement, editor } = element

      // table icon
      const filetoolsicon = toolbarElement.querySelector("[data-trix-button-group=file-tools]")
      filetoolsicon.insertAdjacentHTML("beforeend", tableButtonHTML)

    }, once)
  }
  

  attachTable(event) {
    Rails.ajax({
      url: `/tables`,
      type: 'post',
      success: this.insertTable.bind(this)
    })
  }

  insertTable(tableAttachment) {
    this.attachment = new Trix.Attachment(tableAttachment)
    this.element.querySelector('trix-editor').editor.insertAttachment(this.attachment)
    this.element.focus()
  }
}