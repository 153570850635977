import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form", "input", "result" ]
  static values = {}

  initialize() {
    this.inputTarget.addEventListener('touchend', process_touchend, false);
  }

  connect() {
  }

  disconnect() {
  }

  clear() {
    this.inputTarget.value = ''
    this.resultTarget.classList.add("hidden")
    this.resultTarget.innerHTML = "";
  }

  keyed(event) {
    const input = this.inputTarget.value
    if(event.key === "Escape") {
      this.escaped();
      this.resultTarget.classList.add("hidden");
    } else if(event.key === "Enter") {
      this.resultTarget.classList.remove("hidden")
    } else {
      if(event.key === "Backspace") {
        if(input.length <= 1) {
          this.clear()
        }
      } else {
        this.resultTarget.classList.remove("hidden")
      }
    }
  }

  escaped() {
    this.clear()
  }
}