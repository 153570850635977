import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hovercard"
export default class extends Controller {
  static targets = [ "card" ]
  static values = {
    cardId: String,
    url: String
  }
  connect() {
    this.show
  }
  
  show() {
    if (this.hasCardTarget) {
        this.unhide();
    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);
          this.element.appendChild(fragment);
        });
    }
  }

  unhide() {
    if (this.cardTarget.classList.contains("hidden")) {
      this.cardTarget.classList.remove("hidden");
    }
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("hidden");
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}
