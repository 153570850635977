import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    // Only apply event listeners once to the toolbars
    const once = {
      once: true
    }

    // HTML for our buttons
    const hrButtonHTML        = '<button type="button" class="trix-button" data-trix-action="x-horizontal-rule" tabindex="-1" title="Divider"> — </button>'

    addEventListener("trix-action-invoke", function (event) {
      if (event.actionName == "x-horizontal-rule") insertHorizontalRule()
    
      function insertHorizontalRule() {
        event.target.editor.insertAttachment(buildHorizontalRule())
      }
    
      function buildHorizontalRule() {
        return new Trix.Attachment(
          { 
            content: `
            <div class="flex flex-row justify-center w-full">
              <div class="w-1/3 m-0 flex flex-row justify-center items-center text-gray-700">
                <div class="w-1/12 flex justify-center">•</div>
                <div class="w-1/12 flex justify-center">•</div>
                <div class="w-1/12 flex justify-center">•</div>
              </div>
            </div>
            `, 
            contentType: "vnd.rubyonrails.horizontal-rule.html" 
          }
        )
      }
    }, once)

    addEventListener("trix-initialize", function(event) {

       // Grab a reference to the toolbar
      const element = event.target
      const { toolbarElement, editor } = element

      // hr icon
      const strikeicon = toolbarElement.querySelector(".trix-button--icon-strike")
      strikeicon.insertAdjacentHTML("afterend", hrButtonHTML)
      
    }, once)
  }
}