// Trix plugin for code backticks
Trix.config.textAttributes.inlineCode = { 
  tagName: "code", 
  inheritable: true 
}

Trix.config.textAttributes.mark = {
  tagName: "mark", 
  inheritable: true 
}

Trix.config.textAttributes.underline = {
  style: { textDecoration: "underline" },
  parser: function(element) {
    return element.style.textDecoration === "underline"
  },
  inheritable: 1
}

Trix.config.blockAttributes.heading = {
  tagName: "h2",
  terminal: true,
  breakOnReturn: true,
  group: false
}

Trix.config.blockAttributes.subHeading = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false
}
