import "@hotwired/turbo-rails"
import "./controllers"
import "./trix/trix_exts"
import "trix"
import "@rails/actiontext"
import Rails from "@rails/ujs"
require("@rails/activestorage").start()
Rails.start()

// Put this somewhere in /app/javascript
document.addEventListener("turbo:before-fetch-request", (event) => {
  event.detail.fetchOptions.headers["X-Turbo-Nonce"] =
    document.querySelector("meta[name='csp-nonce']")?.content
})

document.addEventListener("turbo:before-cache", () => {
  document.querySelectorAll("script[nonce]").forEach((element) => {
    element.setAttribute("nonce", element.nonce);
  })
})


