import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    // Only apply event listeners once to the toolbars
    const once = {
      once: true
    }

    // HTML for our buttons
    const h2ButtonHTML        = '<button type="button" class="trix-button" data-trix-attribute="heading"    title="Subheading">H2</button>'
    const h3ButtonHTML        = '<button type="button" class="trix-button" data-trix-attribute="subHeading" title="Subheading">H3</button>'

    addEventListener("trix-initialize", function(event) {

       // Grab a reference to the toolbar
      const element = event.target
      const { toolbarElement, editor } = element

      // heading icons
      const nestingicon = toolbarElement.querySelector(".trix-button--icon-heading-1")
      nestingicon.insertAdjacentHTML("afterend", h2ButtonHTML)
      const h1icon = toolbarElement.querySelector("[data-trix-attribute='heading']")
      h1icon.insertAdjacentHTML("afterend", h3ButtonHTML)

    }, once)
  }
}