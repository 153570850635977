import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      trigger: '[',
      allowSpaces: true,
      spaceSelectsMatch: false,
      lookup: 'reference_name',
      values: this.fetchReferences,
      requireLeadingSpace: true,
      containerClass: 'tribute-container z-50',
      positionMenu: true,
      menuShowMinLength: 0,
      menuItemLimit: 25
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchReferences(text, callback) {
    fetch(`/references.json?q=${text}`)
      .then(response => response.json())
      .then(references => callback(references))
      .catch(error => callback([]))
  }

  replaced(e) {
    let reference = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: reference.sgid,
      content: reference.content,
      contentType: "reference"
    })
    this.editor.insertAttachment(attachment)
    // this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    let tributeLength = endPos - startPos
    let trixStartPos = position - tributeLength
    let trixEndPos = position
    this.editor.setSelectedRange([trixStartPos, trixEndPos])
    this.editor.deleteInDirection("backward")
  }
}