import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

    // Only apply event listeners once to the toolbars
    const once = {
      once: true
    }

    // HTML for our buttons
    const markButtonHTML      = 
      `<button type="button" class="trix-button" data-trix-attribute="mark"       title="mark">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5 flex items-center">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" />
        </svg>
      </button>`

    addEventListener("trix-initialize", function(event) {

       // Grab a reference to the toolbar
      const element = event.target
      const { toolbarElement, editor } = element

      // mark icons
      const strikeicon = toolbarElement.querySelector(".trix-button--icon-strike")
      strikeicon.insertAdjacentHTML("afterend", markButtonHTML)

    }, once)
  }
}